.navbar-brand {
  margin-right: 60px;
  font-size: 48px;
  font-weight: 600;
  line-height: 24px;
  color: #d9d9d9;
  &:hover {
    color: #d9d9d9;
  }
}


.navbar-brand-dot {
  color: #9fffac;
}

.bg-light {
  --cui-bg-opacity: 1;
  background-color: #252525 !important;
}

.header {
  --cui-header-bg: #252525 !important;
  --cui-header-color: rgba(var(--cui-emphasis-color-rgb), 0.65);
  --cui-header-border-color: #252525
}

.mb-4 {
  margin-bottom: 0.5rem !important;
}

.sidebar {
  --cui-sidebar-bg: #252525 !important;
}

.footer {
  --cui-footer-color: #3f3e3e !important;
  --cui-footer-bg: #252525 !important;
}

// ::marker {
//   content: '';
// }

li {
  display: list-item;
  text-align: -webkit-match-parent;
  unicode-bidi: isolate;
}

.dropdown-menu.show {
  display: block;

  margin: auto;
  margin-top: 10px;
}

.pt-0 {
  padding-top: 5px !important;
}


.dropdown-item {
  padding: 10px;
  color: var(--primary);
  font-size: 16px;
  font-weight: 300;
  text-wrap: wrap;
}

.dropdown-item:hover,
.dropdown-item:focus {
  color: var(--primary);
  background-color: var(--badge-bg);
}

.dropdown-menu show pt-0 {
  margin: 10px;
  margin-top: 10px;
}

ul.dropdown-menu.show.pt-0 {
  margin-top: 10px !important;
  margin: '' !important;
  background-color: #3f3e3e;
}

.sidebar-container {
  width: 160px;
}

.sidebar-container.collapsed {
  width: 40px;
}

.side-nav-wd {
  overflow: hidden;
}

.side-nav-collapse {
  /* cursor: pointer;
  padding-bottom: 30px;
  padding-top: 4px;
  display: flex;
  align-items: center; */
  width: 40px;
  height: 40px;
  border-radius: 50%;
  border: 1px solid var(--secondary);
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 32px;
  cursor: pointer;
}

.side-nav-collapse.active {
  /* border: 1px solid;
  border-radius: 50px;
  padding: 8px;
  margin-bottom: 30px; */
  border: 1px solid var(--active);
  color: #9fffac;
}



.side-nav-collapse svg {
  transition: stroke 0.3s;
}


.side-nav-collapse svg {
  fill: var(--active) white;
}


.side-nav-collapse.active svg {
  stroke: #9fffac;
}

.nav-icon {
  display: inline-block;
  width: 24px;
  height: 24px;
}

.nav-text {
  margin-left: 10px;
  transition: opacity 0.3s ease;
  opacity: 1;
}

.sidebar-container.collapsed .nav-icon {
  display: inline-block;
  width: 24px;
  height: 24px;
  color: var(--primary);

}

.sidebar-container.collapsed .nav-text {
  opacity: 0;
  width: 0;
  overflow: hidden;
  white-space: nowrap;
}

.sidebar-item {
  margin-bottom: 20px;
  color: var(--primary);
  border: 1px solid #3f3e3e;
  border-radius: 14px;
  padding: 8px;
}


.sidebar-item .nav-icon svg {
  fill: white;
  stroke: white;
  width: 24px;
  height: 24px;

}

.nav-link.active {
  .sidebar-item .nav-icon svg {
    fill: var(--active)white;
    stroke: var(--active);
    width: 24px;
    height: 24px;

    color: var(--active);

  }
}

.sidebar-item .nav-text {
  color: var(--primary);
  flex-grow: 1;
}

.sidebar-item:hover {
  opacity: 0.8;
}
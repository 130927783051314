// Here you can add other styles
.badge-amber {
    background-color: #FFC107;
}

.badge-green {
    background-color: #4CAF50;
}

.badge-red {
    background-color: #F44336;
}

.badge-blue {
    background-color: #2196F3;
}

.badge-grey {
    background-color: #9E9E9E;
}

.badge-light-grey {
    background-color: #BDBDBD;
}


.grid-container {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(18rem, 1fr));
    //grid-template-rows: repeat(auto-fit, minmax(18rem, 1fr));
    //margin-bottom: 20rem;
    //flex-wrap: wrap;
    row-gap: 20px;
}

.ellipsis {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    color: #9fffac;
}

.title {
    word-wrap: break-word;
    text-transform: capitalize;
}

.button {
    width: auto;
}

.href {
    text-decoration: none;
}

.cursor-pointer {
    cursor: pointer;
}

.questions-list {
    list-style: none;
    padding: 0;
    margin-top: 1rem;
}

// .css-1ex1afd-MuiTableCell-root {
//     max-width: 30rem;
//     border-bottom: none !important;
// }

.badgeSpan {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 7px 8px 4px 8px;
    font-size: 0.8rem;
    width: 7rem;
}

.question-item {
    border-radius: 12px;
    margin-bottom: 8px;
    padding: 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #333232
}

.dropDown {
    background-color: transparent !important;
    border: none;
}

.dropdown-toggle::after {
    display: none;
}

.file-item {
    border-radius: 12px;
    margin-bottom: 8px;
    padding: 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: rgb(111 165 255 / 38%);
    width: 70%;
}

.dropDown {
    background-color: transparent !important;
    border: none !important;
}

.dropdown-toggle::after {
    display: none !important;
}

.custom-dropdown {
    position: relative;
}

.custom-dropdown-menu {
    position: absolute;
    top: 0;
    left: 100%;
    /* Adjust as needed to position the menu correctly */
    margin-left: 10px;
    /* Optional: Add some margin to avoid overlapping too closely */
    z-index: 1050;
    /* Ensure the dropdown menu appears above other elements */
}

.dateCell {
    display: flex;
    min-width: 10rem;
    gap: 1rem;
}

.noDataPage {
    width: 100%;
    height: 85vh;
    align-items: center;
    justify-content: center;
    display: flex;
}

.newProject {
    float: right;
    position: absolute;
    left: 85%;
    bottom: 80%;
}

.btn-primary {
    --cui-btn-border-color: none;
}


.flexCenter {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
}

.disBut {
    background-color: #288848ab;
    color: #0000008f;
}


.delete-button {
    background: none;
    border: none;
    cursor: pointer;
}

.dropdownIconItem {
    display: flex;
    gap: 5px;
}

.delete-button:hover {
    opacity: 0.4;
    background-color: none;

}



.question-order {
    font-style: normal;
    font-weight: 300;
    font-size: 16px;
    line-height: 150%;
    color: var(--primary);
    max-width: 80%;
}

.slNumbers {
    border: none;
    background-color: var(--secondary);
    ;
    color: black;
    border-radius: 50%;
    // cursor: pointer;
    font-weight: bold;
    height: 2rem;
    width: 2rem;
    display: flex;
    align-items: center;
    justify-content: center;
}

.quesHeader {
    color: var(--primary) !important;
}

.quesDiv {
    display: flex;
    gap: 1rem;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
}

// .delete-button {
//     border: none;
//     background-color: #ff4d4f;
//     color: var(--primary);
//     border-radius: 50%;
//     cursor: pointer;
//     font-weight: bold;
//     height: 2rem;
//     width: 2rem;
// }

// .delete-button:hover {
//     opacity: 0.8;
// }

.regCard {
    display: flex;
    flex-direction: column;
    gap: 1rem;
}



.labelBtn {
    display: inline-block;
    padding: 6px 12px;
    margin-bottom: 0;
    // font-size: 14px;
    font-weight: 400;
    line-height: 1.42857143;
    text-align: center;
    white-space: nowrap;
    vertical-align: middle;
    cursor: pointer;
    border: 1px solid transparent;
    border-radius: 4px;
    color: var(--primary);
    ;
    // background-color: #337ab7;
    // border-color: #2e6da4;
    // background-color: var(--active) !important;
    color: #000000;
}

.error-message {
    color: red;
    font-size: 1rem;
}


.countBg {
    background-color: #333232;
    padding: 10px;
    border-radius: 10px;
}

.empty-placeholder {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 25rem;
    width: 100%;
}

.pointer {
    cursor: pointer;
}

.ellipsis {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.mt-1 {
    margin-top: 1rem !important;
}

.mt-1 {
    margin-bottom: 1rem !important;
}

.bg-o {
    background-color: #f39400 !important;
}

.font22 {
    font-size: 22px !important;
}

.text-dark {
    color: rgb(16 65 143) !important;
}

.bg-primary {
    --cui-bg-opacity: 1;
    background-color: #8b8b8b !important;
}

p {
    margin: 0;
}

.fileDiv {
    display: flex;
    align-items: center;
    gap: 1rem;
}

.dataCol {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 1rem;
}

.form-switch {
    display: flex;
    gap: 0.5rem;
}

.filterDiv {
    display: flex;
    flex-direction: column;
    width: 50%;
    margin-top: 3rem;
}

.language-checkboxes {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    /* Adjust the number of columns as needed */
    gap: 0.5rem;
    /* Space between the grid items */
    column-gap: 3rem;
    width: 15rem;
    /* Adjust width as needed */
    height: auto;
    /* Adjust height as needed */
    padding: 1rem;
    /* Optional: Add padding for better spacing */
}

.language-checkboxes div {
    display: flex;
    align-items: center;
    gap: 0.5rem;
}

.flex {
    display: flex;
}

.noteDiv {
    margin: 2rem 0rem 2rem;
    display: flex;
    align-items: flex-start;
    gap: 1rem;
    flex-direction: column;
}

.createAssesment {
    display: flex;
    justify-content: flex-end;
    margin-bottom: 1rem;
    margin-top: 1rem;
}


//////////////////quescard////////////////////\
.custom-card {
    margin-top: 1rem;
    border: none;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
}

// .card-header {
//     // border-bottom: 2px solid #007bff;
//     padding-bottom: 0.5rem;
//     margin-bottom: 1rem;
//     // background-color: #4db8ff99;
// }

.note-div {
    // margin-bottom: 2rem;
    font-size: 15px;
}

.guide-title {
    margin-bottom: 1rem;
    color: #007bff;
}

.mr-1 {
    margin-right: 1rem;
}

.fs-1rem {
    font-size: 1rem;
}

.graphRow {
    margin-top: 0.8rem !important;
    margin-left: 0rem !important;
    margin-right: 0rem !important;
    height: 29rem !important;
}

.CircularProgressbar {
    height: 15rem !important;
}

.flex-evenly {
    display: flex;
    align-items: center;
    justify-content: space-evenly;
}

.check {
    width: 1.3rem;
    height: 1.3rem;
    margin: 0;
}

.dateDiv {
    font-size: 18px;
    font-weight: 300;
    margin-left: 0.5rem;
}

.guide-list {
    list-style-type: decimal;
    /* Removes default bullets */
    padding-left: 0;
    /* Removes default padding */
    margin-bottom: 0.5rem;
}

.guide-list li {
    margin-bottom: 10px;
    /* Adds spacing between items */
}

.deplyIcon {
    width: 23px;
    height: 23px;
}

.summaryLangDiv {
    display: flex;
    align-items: center;
    // justify-content: center;
    margin-top: 1rem;
    flex-wrap: wrap;
    gap: 0.3rem;
}

.subDiv {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
}

.file-select-area:hover .empty-placeholder {
    border-color: var(--primary-dark-bg);
    /* Change the border color on hover */
    background-color: var(--secondary-dark-bg);
    /* Optional: Add a subtle background color on hover */
}


.flexEnd {
    display: flex;
    justify-content: flex-end;
    gap: 1rem;
}

.deployDiv {
    width: 1rem;
    height: 2rem;
    display: flex;
    align-items: center;
    justify-content: center;
}

.guide-list .nested {
    list-style-type: disc;
    /* Adds decimal numbers for nested items */
    padding-left: 20px;
    /* Adds indentation to nested items */
}

.accordion-button {
    padding: 0.8rem 0.8rem;
    color: var(--primary);
    font-weight: 300;
}

.accordion-button::after {
    background-image: url("../assets/images/downArrow.svg") !important;
}

.accordion-button:hover {
    // background-color: var(--secondary-dark-bg) !important;

}

.accordion {
    --cui-accordion-bg: var(--primary-dark-bg) !important;
    --cui-accordion-border-color: var(--secondary-dark-bg) !important;
}

.accordion-button:not(.collapsed) {
    color: var(--primary) !important;
    background-color: var(--primary-dark-bg) !important;
}

.accordion-button:focus {
    box-shadow: 0 0 0 1px var(--primary) !important;
}


.accordion-button::after {
    background-image: url("../assets/images/downArrow.svg") !important;
}


.accordion-item {
    border: var(--cui-accordion-border-width) solid var(--secondary-dark-bg);
    ;
    --cui-accordion-border-color: var(--secondary-dark-bg) !important;
}


.multipleDrop {
    font-size: 0.9rem;
    margin-left: 0.5rem;
}

.file-div {
    display: flex;
    align-items: center;
}

.custom-file-input {
    // padding: 10px 20px;
    cursor: pointer;
    color: var(--primary);
    border: 1px solid rgb(61, 211, 50) !important;
    background-color: rgba(61, 211, 50, 0.2);
    border: none;
    border-radius: 4px;
    margin-right: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 1rem;
}

.custom-file-input:hover {
    background-color: rgba(61, 211, 50, 0.148);
}

.selected-file {
    flex-grow: 1;
}

.rowDiv {
    display: flex;
    width: 30rem;
    gap: 2rem;
    align-items: center;
    justify-content: flex-start;
}

//////////////////quescard////////////////////\
/// 
/// 
/// 


.custom-card {
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    overflow: hidden;
    margin-top: 1rem;
}

.card-header-step {
    background-color: #4db8ff;
    color: var(--primary);
    padding: 15px 20px;
    font-size: 1rem;
    font-weight: 500;
}

.note-div,
.fileDiv {
    padding: 20px;
}

.note-div ol {
    counter-reset: item;
    padding-left: 20px;
}

.note-div ol li {
    counter-increment: item;
    margin-bottom: 10px;
}

.note-div ol li::before {
    font-weight: bold;
    margin-right: 5px;
}

.guide-title {
    font-size: 18px;
    color: #333;
    margin-bottom: 15px;
}

// .labelBtn {
//     background-color: #5cb85c;
//     color: #5cb85c;
//     border: none;
//     padding: 10px 20px;
//     border-radius: 5px;
//     cursor: pointer;
//     margin-right: 15px;
// }

.custom-file-input {
    cursor: pointer;
}

.error-message {
    color: #d9534f;
    font-size: 18px;
    margin-top: 10px;
}

.progressbar {
    list-style: none;
    display: flex;
    justify-content: space-evenly;
    padding: 0;
}

.step {
    cursor: pointer;
    padding: 10px 15px;
    border-radius: 20px;
    background-color: var(--secondary);
    ;
    // color: #a9a4a4;
    transition: background-color 0.3s, transform 0.3s;
    width: 13rem;
    font-size: 16px;
    line-height: 150%;
    color: var(--black);
}

.step:hover {
    background-color: #e7e7e7;
    transform: translateY(-2px);
}

.step.active {
    background-color: #9fffac;
    color: rgb(0, 0, 0);
}


// .container {
//     margin-top: 2rem;
// }




.nameTag {
    height: 2rem;
    padding: 0.5rem;
    width: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 5px;
    color: black;
    font-weight: 500;
    font-size: 1.3rem;
}

.phoneTag {
    background-color: #ffd864;
}

.userTag {
    font-size: 16px;
    color: var(--black);
    background-color: var(--primary-light-bg);
    outline-color: var(--active);
    border-color: var(--primary-light-bg);

    &:hover {
        background-color: var(--primary-light-bg);
        outline-color: var(--active);
        background-color: var(--primary-light-bg);
        color: var(--black) !important;
        border-color: var(--active);
    }
}

.multiple-choice {
    font-size: 16px;
    color: var(--black);
    background-color: var(--primary-light-bg) !important;
    outline-color: var(--active) !important;

    &:hover {
        background-color: var(--primary-light-bg) !important;
        outline-color: var(--active) !important;
    }
}

.nameDiv {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 6rem;
}


.numDiv {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 2rem;
    height: 2rem;
    background-color: var(--primary);
    border-radius: 5rem;
}

.mainNavDiv {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1rem;
}

.tempIcon {
    width: 2rem;
}

.iconClass {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 1rem;
    width: 100%;
    height: 1rem;
    margin-top: 5px;
}

.icon {
    width: 20px !important;
    height: 20px !important;
    font-size: 1rem;
}

.flexDiv {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 1rem;
}

.descInput {
    height: 3rem;
    width: auto;
    border: none;
    background-color: transparent;
    font-size: 18px;
}

.assessmentCardText {
    min-height: 4.4rem;
    color: var(--primary);
    font-size: 1rem;
    font-style: normal;
    font-weight: 200;
    line-height: 170%;
    text-transform: capitalize;
}

.descInput:focus {
    outline: #d8dbe0 auto 1px;
}

.flexStart {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 1rem;
}

.flexSpace {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 1rem;
}

.flexRow {
    display: flex;
    flex-direction: row;
    gap: 1rem;
    align-items: baseline;

    color: var(--primary) !important;
}

.flexGap5 {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 0.5rem;
}

.flexgap1 {
    display: flex;
    gap: 1rem;
}

.flexGap5 {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 0.5rem;
}

.flexColumn {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    justify-content: flex-start;
}

.total-no-questions {
    font-style: normal;
    font-weight: 300;
    font-size: 24px;
    line-height: 150%;
    color: var(--primary);
}

.total-no-questions {
    font-style: normal;
    font-weight: 300;
    font-size: 24px;
    line-height: 150%;
    color: var(--primary);
}

.gap0 {
    gap: 0 !important;
}

.searchCreate {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 2rem;
}

.defaultLayout {
    background-color: var(--secondary-dark-bg);
    border-radius: 20px;
    max-height: calc(100vh - 96px);
    min-height: calc(100vh - 120px);
    overflow: auto;
    padding: 12px;
    width: 100%;
    overflow: scroll;
    -ms-overflow-style: none;
    scrollbar-width: none;
}

.defaultLayout::-webkit-scrollbar {
    display: none;
    /* for Chrome, Safari, and Opera */
}

.mainHeaderDiv {
    display: flex;
    justify-content: space-between;
    align-items: center;
}


.mainTitle {
    font-style: normal;
    font-weight: 300;
    font-size: 24px;
    line-height: 150%;
    color: var(--primary);
}

.greeting-sec-header {
    font-style: normal;
    font-size: 20px;
    line-height: 150%;
    display: flex;
    align-items: center;
    color: var(--primary);
    border-bottom: 1px solid var(--primary);
    font-weight: 300;
}

.text-area-style {
    font-size: 16px;
    font-weight: 300;
    outline-color: var(--active);
    color: var(--primary) !important;
    background-color: var(--secondary-dark-bg) !important;
    border: none;
}


form-control:disabled {
    font-size: 16px;
    outline-color: var(--active);
    color: var(--primary) !important;
    background-color: var(--secondary-dark-bg);
}

.form-control {
    background-color: var(--secondary-dark-bg) !important;
    color: var(--primary);
    border: 0.5px solid var(--primary-light-bg);
    font-weight: 300;
}

.form-control::placeholder {
    color: var(--primary-light-bg);
}

.form-control:focus {
    box-shadow: none;
    border-color: var(--active);
    color: var(--primary);
    border-width: 1px !important;
}

.btn:hover {
    color: rgb(255 255 255 / 95%);
}

.step1NumDiv {
    display: flex;
    justify-content: space-between;
    padding: 1rem 1rem 0 1rem;
    align-items: center;
}

.one-gap {
    display: flex;
    gap: 1rem;
}

h5 {
    margin: none;
}

/* Custom CSS for subtab names */
.sub-tabs .nav-link {
    font-size: 18px;
    color: #d9d9d9;
    padding: 5px 15px;
    // border: 1px solid transparent;
    border-radius: 5px;
}

.sub-tabs .nav-link.active {
    background-color: var(--primary-dark-bg);
    color: var(--primary);
}

.no-underline {
    text-decoration: none;
    color: inherit;
}


.descInput {
    scrollbar-width: thin;
    /* For Firefox */
}

/* Webkit-based browsers (Chrome, Safari) */
.descInput::-webkit-scrollbar {
    width: 6px;
    /* Width of the scrollbar */
}

.descInput::-webkit-scrollbar-track {
    background: #f1f1f1;
    /* Background of the track */
}

.descInput::-webkit-scrollbar-thumb {
    background: #888;
    /* Color of the scrollbar handle */
}

.descInput::-webkit-scrollbar-thumb:hover {
    background: #555;
    /* Handle color on hover */
}

// .css-1q1u3t4-MuiTableRow-root {
//     background-color: #252525 !important;
// }
// .css-jtlhu6-MuiTablePagination-root {
//     background-color: #252525 !important;
// }

// .css-vjrmk1-MuiPaper-root {
//     background-color: #252525 !important;
//     border-radius: 0px !important;
// }
// thead, tbody, tfoot, tr, td, th {
//     border-width: 1px thin !important;
//     border-style: 1px solid !important;
// }
// .css-1ex1afd-MuiTableCell-root {
//     color: var(--primary) !important;
// }
// .css-1ygcj2i-MuiTableCell-root {
//     color: var(--primary) !important;
//     border-bottom: none !important;
// }
// .css-1qgma8u-MuiButtonBase-root-MuiTableSortLabel-root.Mui-active {
//     color: var(--primary) !important;
// }
// .css-1ps6pg7-MuiPaper-root{
//     background-color:#252525 !important;
// }
// .css-1qgma8u-MuiButtonBase-root-MuiTableSortLabel-root.Mui-active .MuiTableSortLabel-icon {
//     color: var(--primary) !important;
// }
a {
    color: var(--primary);
    text-decoration: underline;
}

.text-link {
    color: #d9d9d9;
}

.dropdown-menu {
    --cui-dropdown-bg: #555;
}

// .css-e4w4as-MuiFormLabel-root-MuiInputLabel-root { 
//     color: #9fffac !important
// }
// .css-10botns-MuiInputBase-input-MuiFilledInput-input {
//     background: #333232 !important;
// }

// .css-o943dk-MuiFormLabel-root-MuiInputLabel-root {
//     color: #9fffac !important
// }
.modal-content {
    background-color: #333232;
    color: var(--primary);
}


// .modal-footer {
//     border-top: none !important;
// }

.btn-secondary {
    --cui-btn-border-color: var(--active) !important;
    background-color: transparent;
    color: var(--white);
    border: 2px solid var(--active);

    &:hover {
        background-color: transparent;
        color: var(--white);
        border: 2px solid var(--active);
    }
}

.dropdown-toggle {
    // border: 1px solid var(--active) !important;
}

.btn-primary {
    background-color: var(--active) !important;
    color: #000000;

    &:hover {
        background-color: var(--active) !important;
        color: #000000;

    }
}

.btn-outline-secondary {
    &:hover {
        background-color: var(--primary-light-bg);
        color: #000000;
    }
}

.MuiPagination-root {
    button {
        color: var(--primary) !important;

        &.Mui-selected {
            background-color: var(--primary-light-bg);

            &:hover {
                background-color: var(--secondary);
            }
        }
    }
}

.MuiTablePagination-actions {
    button {
        color: var(--primary) !important;

        &.Mui-disabled {
            background-color: transparent;
            color: var(--secondary) !important;
        }

        &.Mui-selected {
            background-color: var(--primary-light-bg);

            &:hover {
                background-color: var(--secondary);
            }
        }
    }
}

.btn-ghost-primary {
    color: var(--active) !important;

    &:hover {
        background-color: var(--active) !important;
        color: #000000 !important;
        border: none;
    }
}

// textarea.form-control {
//     margin-bottom: 15px;
//     color: #000000 !important;

//     border: none !important;
// }
hr {
    border-color: var(--primary);
}

.summary-badge {
    background: var(--primary-light-bg);
    color: var(--primary-dark-bg) !important
}

.qsummary-card {
    background-color: var(--badge-bg) !important;
    color: var(--primary);

    .card-header {
        background-color: var(--badge-bg) !important;
        border-color: var(--primary-light-bg);
    }
}

.chart-summary {
    background: var(--primary-dark-bg);
}

.query-insights-card {}

.btn-close {
    --cui-btn-close-bg: url('../assets/images/xmark-solid.svg') !important;
}

.modal-border-bottom {
    border-bottom: 1px solid var(--secondary) !important;
}

.modal-border-top {
    border-top: 1px solid var(--secondary) !important;
}

.modal-header {
    color: #9fffac !important;
    border-bottom: none;
    font-weight: 600;
}

.modal-footer {
    border-top: none;
}

.multple-choice-table {
    background: var(--primary-dark-bg) !important;

    th,
    td {
        color: var(--primary) !important;
        border: 1px solid var(--secondary-dark-bg);
        font-family: 'Poppins';
        font-size: 16px;
        font-weight: 200;
    }

    th {
        font-weight: 400;
    }
}

.font18 {
    font-size: 18px;
}

.btn:disabled,
.btn.disabled,
fieldset:disabled .btn {
    color: #000000 !important;
}

// .MuiToolbar-root MuiToolbar-gutters MuiToolbar-regular MuiTablePagination-toolbar css-78c6dr-MuiToolbar-root-MuiTablePagination-toolbar {
//     color: var(--primary) !important;
// }

// .css-zylse7-MuiButtonBase-root-MuiIconButton-root.Mui-disabled {
//     color: var(--primary) !important;
// }

.ccard {
    background-color: #252525 !important;
}

.assessment-card {
    border: none;
    height: 100%;
    background: var(--badge-bg);
    display: flex;
    justify-content: space-between;
    flex-direction: column;
}

.assessment-card-row {
    gap: 3.5%;
    row-gap: 3.5rem;
    padding: 0 5%;
}

.assessment-card-col {
    background: var(--badge-bg);
    box-shadow: 0 20px 50px #0000001a;
    border-radius: 20px;
    min-height: 21rem;
    height: auto;
    padding: 1rem;
    margin: 10px;
    width: 27%;
}

.summaryCards {
    background-color: var(--badge-bg);
    box-shadow: 0 20px 50px #0000001a;
    color: var(--primary) !important;
}

.view-data-header {
    font-style: normal;
    font-weight: 300;
    font-size: 24px;
    line-height: 150%;
    display: flex;
    align-items: center;
    color: var(--primary);
    border-bottom: 1px solid var(--primary);
}

.descInput {
    font-style: normal;
    font-weight: 300;
    font-size: 16px;
    line-height: 150%;
    color: var(--primary);
    // opacity: .8;

}


.guide-list {
    color: var(--primary) !important;
}



.descInput {
    color: var(--primary) !important;
}

.form-check-label {
    color: var(--primary);
}

.form-check-input:checked {
    background-color: #9fffac !important;
    border-color: #9fffac !important;
}

.textcolor {
    color: var(--primary) !important;
}

.response {
    color: #9fffac !important;
}

.text-medium-emphasis {
    --cui-text-opacity: 1;
    color: var(--primary) !important;
}

.questions-background {
    background-color: var(--primary-dark-bg) !important;
    border: 1px solid var(--primary-light-bg);
}

.form-check-input[type=checkbox]:indeterminate {
    background-color: var(--active);
    border-color: var(--active);
}

.list-group-item {
    color: var(--primary);
    background-color: #333232
}

.list-group {
    --cui-list-group-border-width: none !important;
}

.assessmentdetails-body {
    background-color: #333232;
}

.text-secondary {
    color: #000000 !important;
}

.spinner {
    color: var(--primary) !important;
}

.card-height {
    height: 100%;
    max-height: 100%;
}

.table {
    --cui-table-bg: '';
}

.nav-tabs .nav-link.active,
.nav-tabs .nav-item.show .nav-link {
    // color: black !important;
}

.nav-item {
    list-style: none;
}

.question-summary-header {
    font-style: normal;
    font-weight: 300;
    font-size: 24px;
    line-height: 150%;
    color: var(--primary);
    margin-bottom: 1rem;
}

.status-icon {
    border-radius: 50%;
}

.status-open {
    color: rgb(254, 235, 203);
}

.status-complete {
    color: rgb(190, 227, 248);
}

.status-text {
    margin-left: 8px;
    font-style: normal;
    font-size: 1.1rem;
    font-weight: 700;
    line-height: 1.16919rem;
    text-transform: uppercase;
}

.assessment-card-details {
    color: #fff;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 159.5%;
    text-transform: capitalize;
}

.report-card {
    background-color: var(--primary-dark-bg);
    // border-color: var(--primary-light-bg);
}

.survey-card {
    background-color: var(--badge-bg);

    .card-header {
        border-color: var(--primary-light-bg);
        color: var(--active);
    }
}

.assessment-verify-card {
    background-color: var(--badge-bg);
    color: var(--primary);

    .card-header {
        border-color: var(--primary-light-bg);
        color: var(--active);
        font-size: 24px;
    }
}

.query-insights-card {
    background-color: var(--primary-dark-bg);
    color: var(--primary);
}


.card-fixed-size {
    width: 306px;
    height: 359px;
}

.card-text-fixed-size {
    width: 256px;
    height: 86px;
}

.assessment-card-title {
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: 170%;
    text-transform: capitalize;
}

.markdowntextcolor {
    color: var(--primary) !important;
    font-family: Poppins;
}

.chartcard {
    background-color: #3f3e3e !important;
    color: var(--primary);
    .card-header {
        border-color: var(--primary-light-bg);
        color: var(--active);
    }
}
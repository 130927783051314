/*
 * Application global variables.
 */

// Set Font Awesome font path
$fa-font-path: "~@fortawesome/fontawesome-free/webfonts";

// ---------------------------------------------------------------------------
// Bootstrap variables
//
// Override Bootstrap variables here to suite your theme.
// Copy variables you want to customize from node_modules/bootstrap/scss/_variables.scss

//
// Color system
//

// $white: #fff;
// $gray-100: #f8f9fa;
// $gray-200: #e9ecef;
// $gray-300: #dee2e6;
// $gray-400: #ced4da;
// $gray-500: #adb5bd;
// $gray-600: #868e96;
// $gray-700: #495057;
// $gray-800: #343a40;
// $gray-900: #212529;
// $black: #000;

// $blue: #0073dd;
// $indigo: #6610f2;
// $purple: #6f42c1;
// $pink: #e83e8c;
// $red: #dc3545;
// $orange: #fd7e14;
// $yellow: #ffc107;
// $green: #28a745;
// $teal: #20c997;
// $cyan: #17a2b8;

$white: #fff;
$black: #000;
$gray-100: #d9d9d9;
$gray-500: #919191;
$gray-800: #3f3e3e;
$gray-900: #252525;
$green-500: #9fffac;

$theme-colors: (
  primary: $green-500,
  secondary: $gray-500,
  lightgray: $gray-800,
  darkgray: $gray-900,
  active: $green-500,
);
:root {
  --white: #fff;
  --black: #000;
  --primary: #d9d9d9;
  --primary-light-bg: #a6a6a6;
  --primary-dark-bg: #252525;
  --secondary: #919191;
  --secondary-dark-bg: #3f3e3e;
  --active: #9fffac;
  --badge-bg: #333232;
  --side-nav-bg: #181818;
  --border-color: var(--secondary-dark-bg);
}
// html {
//   height: 100vh;
//   overflow: hidden;
// }

// body {
//   background-color: var(--primary-dark-bg);
//   color: var(--primary);
//   font-family: "Poppins";
//   font-weight: 300;
//   font-size: 16px;
// }
// Use Bootstrap defaults for other variables, imported here so we can access all app variables in one place when used
// in components.

@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300;500;600;700&display=swap");

.summary-container {
    font-family: Arial, sans-serif;
    padding: 20px;
  }
  
  .summary-navigation {
    margin-bottom: 20px;
    font-size: 14px;
    color: #555;
  }
  
  .survey-monkey-genius {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 20px;
  }
  
  .completion-circle {
    width: 80px;
    height: 80px;
    border-radius: 50%;
    background: conic-gradient(green 76%, #f0f0f0 0%);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: 14px;
    font-weight: bold;
    color: green;
  }
  
  .bar-chart {
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
  }
  
  .bar {
    width: 18%;
    background-color: green;
    text-align: center;
    color: white;
    display: flex;
    align-items: flex-end;
    justify-content: center;
    font-size: 12px;
    padding: 5px;
  }
  body {
    font-family: 'Poppins', sans-serif;
  }
  
  
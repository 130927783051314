// If you want to override variables do it here
@import "variables";

$enable-ltr: true;
$enable-rtl: true;

// Import CoreUI for React components library
@import "@coreui/coreui/scss/coreui";

// Import Chart.js custom tooltips styles
@import "@coreui/chartjs/scss/coreui-chartjs";

@import "layout";
@import "example";

// If you want to add custom CSS you can put it here.
@import "custom";

@import "component";

@import "theme";

@import "summary";
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300;500;600;700&display=swap");


body {
    background-color: var(--primary-dark-bg);
    color: #d9d9d9 !important;
    font-family: "Poppins";
    font-weight: 300;
    font-size: 16px;
  }

  h6, .h6, h5, .h5, h4, .h4, h3, .h3, h2, .h2, h1, .h1  {
    color: #d9d9d9 !important;
  }